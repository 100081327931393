// import { groundPlain } from "./Methods/groundPlain";
// import { shootingStar } from "./Methods/shootingStar";
const rotationPower = 0.01;
// let index = 0;
export const animation = (threeObj) => {
  // index++;
  // if (index % 2 === 0) {
  // groundPlain.update();
  // shootingStar.update();
  threeObj.group.rotation.x += rotationPower;
  threeObj.group.rotation.y += rotationPower;
  threeObj.renderer.render(threeObj.scene, threeObj.camera);
  // }
};
