import "./Main.css";

import ip from "../../assets/img/smoke.png";
import masthead from "../../assets/img/4.jpg";
import dem from "../../assets/img/3.jpg";
import dem2 from "../../assets/img/7.jpg";
import dem3 from "../../assets/img/9.jpg";
import dem4 from "../../assets/img/10.jpg";
import u5 from "../../assets/img/U5.png";
import SceneManager from "../Page3Canvas/SceneManager";
// import { useRef } from "react";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";

function Main() {
  // Navbar shrink function
  // var navbarShrink = function () {
  //   const navbarCollapsible = document.body.querySelector("#mainNav");
  //   if (!navbarCollapsible) {
  //     return;
  //   }
  //   if (window.scrollY === 0) {
  //     navbarCollapsible.classList.remove("navbar-shrink");
  //   } else {
  //     navbarCollapsible.classList.add("navbar-shrink");
  //   }
  // };

  // Shrink the navbar
  // navbarShrink();

  // Shrink the navbar when page is scrolled
  // document.addEventListener("scroll", navbarShrink);

  // // Activate Bootstrap scrollspy on the main nav element
  // const mainNav = document.body.querySelector("#mainNav");
  // if (mainNav) {
  //   // eslint-disable-next-line no-undef
  //   new bootstrap.ScrollSpy(document.body, {
  //     target: "#mainNav",
  //     offset: 74,
  //   });
  // }

  // // Collapse responsive navbar when toggler is visible
  // const navbarToggler = document.body.querySelector(".navbar-toggler");
  // const responsiveNavItems = [].slice.call(
  //   document.querySelectorAll("#navbarResponsive .nav-link")
  // );
  // console.log(responsiveNavItems);
  // responsiveNavItems.map(function (responsiveNavItem) {
  //   responsiveNavItem.addEventListener("click", () => {
  //     if (window.getComputedStyle(navbarToggler).display !== "none") {
  //       navbarToggler.click();
  //     }
  //   });
  // });
  return (
    <>
      <SceneManager></SceneManager>
      {/* <!-- Navigation--> */}
      <Navbar
        bg="light"
        expand="lg"
        // sticky="top"
        // variant="dark"
        className="fixed-top"
      >
        <Container fluid>
          <Navbar.Brand href="#page-top">Swag Wave</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mr-auto"
              style={{ maxHeight: "160px" }}
              navbarScroll
            >
              <Nav.Link href="#about">What Does "Swag" Mean</Nav.Link>
              <Nav.Link href="#Business">Business</Nav.Link>
              <Nav.Link href="#Recruit">Recruit</Nav.Link>
              <Nav.Link href="#Message">Message</Nav.Link>
              <Nav.Link href="#Company">Company</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <!-- Masthead--> */}

      <header className="masthead" id="page-top">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center ">
          <div
            className="d-flex justify-content-center"
            style={{ minWidth: "400px" }}
          >
            <div className="text-center min-vw-400">
              <h1 className="mx-auto my-0 text-uppercase mt-5">Swag Wave</h1>
              <h2 className="text-white-50 mx-auto mt-2 mb-5 ">
                LLC. Swag Wave established in 2023
              </h2>
              {/* <a className="btn btn-primary" href="#about">
                Get Started
              </a> */}
            </div>
          </div>
        </div>
      </header>
      {/* <!-- About--> */}
      <section className="about-section text-center s-1" id="about">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8">
              <h2 className="text-white mb-4">What Does "Swag" Mean</h2>
              <p className="text-white-50">
                Swagとはスラングで「イケてる」という意味です。
              </p>
              <p className="text-white-50 font">
                音楽業界でもIT業界でもイケてると言うワードは良く耳にします。
              </p>
              <p className="text-white-50">弊社 Swag Waveは</p>
              <p className="text-white-50">
                「イケてる波を生み出す」「イケてる波に乗る」と言う願いを社名に込めております。
              </p>
            </div>
          </div>
        </div>
        <img className="img-fluid wid" src={ip} alt="..." />
      </section>
      {/* <!-- Projects--> */}
      <section className="projects-section bg-light s-2" id="Business">
        <div className="container px-4 px-lg-5">
          {/* <!-- Featured Project Row--> */}
          <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
            <div className="col-xl-8 col-lg-7">
              <img
                className="img-fluid mb-3 mb-lg-0"
                src={masthead}
                alt="..."
              />
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="featured-text text-center text-lg-left">
                <h4 className="text-white">Business</h4>
                <p className="mb-0 text-white-50">
                  弊社は、IT事業とMUSIC事業を基軸に事業展開しております。
                  常にお客様のニーズに合わせた最適なソリューションを提供し、ビジネスの発展と成長をサポートしています。
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <h2 className="text-white mb-5">Service</h2>
          </div>
          {/* <!-- Project One Row--> */}
          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center ">
            <div className="col-lg-6">
              <img className="img-fluid" src={dem} alt="..." />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project ">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left">
                    <h4 className="text-white">SES</h4>
                    <p className="mb-0 text-white-50">
                      フロントエンド開発に特化したSEの派遣業務を行っております。また、DXに関する相談やPOC開発も承っております。お客様のニーズに合わせて、最適なソリューションを提供いたしますので、ご要望がございましたらお気軽にお問い合わせください。
                    </p>
                    <hr className="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Project Two Row--> */}
          <div className="row gx-0 justify-content-center">
            <div className="col-lg-6">
              <img className="img-fluid" src={dem2} alt="..." />
            </div>
            <div className="col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right">
                    <h4 className="text-white">MUSIC</h4>
                    <p className="mb-0 text-white-50">
                      K-popやR&Bのサウンドトラック制作や楽曲制作業務、アーティストプロデュース、ボイストレーニング業務を行っています。高度な技術と専門知識を持つプロフェッショナルたちが、最高品質のサポートを提供します。お客様のご要望に合わせてカスタマイズも可能ですので、お気軽にお問い合わせください。
                    </p>
                    <hr className="d-none d-lg-block mb-0 me-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Project three Row--> */}
          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center ">
            <div className="col-lg-6">
              <img className="img-fluid" src={dem3} alt="..." />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project ">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left">
                    <h4 className="text-white">SNS</h4>
                    <p className="mb-0 text-white-50">
                      SNS 運用代行コンサル事業を提供しています。お客様の SNS
                      アカウントを代わりに運用し、フォロワー数やエンゲージメントを増やします。お客様のターゲットに合わせて、最適な
                      SNS
                      戦略を提案し、実際に投稿や提案を行います。ご要望がございましたらお気軽にお問い合わせください。{" "}
                    </p>
                    <hr className="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Project four Row--> */}
          <div className="row gx-0 justify-content-center">
            <div className="col-lg-6">
              <img className="img-fluid" src={dem4} alt="..." />
            </div>
            <div className="col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right">
                    <h4 className="text-white">WEB PAGE</h4>
                    <p className="mb-0 text-white-50">
                      ホームページ制作及びランディングページ制作サービス。
                      <br></br>
                      ホームページ :
                      完全オリジナルでの作成、さらにランニングコストを抑え、最適なソリューションを提供いたします。
                      <br></br>
                      ランディングページ :
                      3Dに特化した完全オリジナルページを制作いたします。
                    </p>
                    <hr className="d-none d-lg-block mb-0 me-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Signup--> */}
      <section className="signup-section2" id="Recruit">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-10 col-lg-8 mx-auto text-center">
              <i className="far fa-paper-plane fa-2x mb-2 text-white "></i>
              <h2 className="text-white mb-5">Recruit</h2>
              <p className="mb-0 text-white-50 ">
                <a href="https://swag-wave-recruit.com/">採用ページはこちら</a>
              </p>
              <br />
              <p className="mb-0 text-white-50 mb-5">
                ※現在音楽業務の求人は行っておりません。
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Signup2--> */}
      <section className="signup-section" id="Message">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-10 col-lg-8 mx-auto text-center">
              <i className="far fa-paper-plane fa-2x mb-2 text-white "></i>
              <h2 className="text-white mb-5">Message</h2>
              <p className="mb-0 text-white-50 ">
                Swag
                Waveに関わる全ての方々と協力し、より良い世界の発展と貢献を目指すことが、私の生涯にわたる目標です。私たちは手を取り合って、より良い未来を築いていくことができると信じています。
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact--> */}
      <section className="contact-section bg-black s-3" id="Company">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 ">
            <h2 className="text-white mb-5 text-center"> Company</h2>
            <div className="container  w-60 company-box">
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-50">会社名</div>
                <div className="col-7 w-100 ">合同会社 スワッグウェーブ</div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-50">所在地</div>
                <div className="col-7 w-100 ">千葉県船橋市湊町2-8</div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-50">代表者名</div>
                <div className="col-7 w-100">武内佑悟</div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-50">事業内容</div>
                <div className="col-7 w-100">
                  <div className="col-40">
                    IT : SES事業 POC開発 Web3Dシステム開発 DX
                    <br />
                    MUSIC : 作詞作曲編曲 soundtrack制作 ArtistProduce
                    VoiceTraining
                  </div>
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-50">ウェブ</div>
                <div className="col-7 w-100">
                  <a href="https://swag-wave.com/">https://swag-wave.com/</a>
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-50">メール</div>
                <div className="col-7 w-100">company@swag-wave.com</div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 fw-bold w-100">設立年月日</div>
                <div className="col-7 w-100">2023年3月7日</div>
              </div>
            </div>
          </div>
          <div className="social d-flex justify-content-center">
            <a className="mx-2" href="#page-top">
              <img className="img-fluid log" src={u5} alt="..." />
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Footer--> */}
      <footer className="footer bg-black small text-center text-white-50">
        <div className="container px-4 px-lg-5">
          Copyright &copy; LLC.SwagWave 2023
        </div>
      </footer>
    </>
  );
}

export default Main;
